
// Run IIFY
(() => {
    const loader = '<div class="loader-container"><div class="loader"></div></div>';

    Array.from(document.querySelectorAll('.program__more a')).forEach((button) => {
        let opened = false;
        button.addEventListener('click', (e) => {
            e.preventDefault();

            opened = !opened;
            button.innerHTML = opened ? 'Zatvoriť' : 'Viac informácii';
            if (opened) {
                button.parentElement.nextElementSibling.classList.add('opened');
            } else {
                button.parentElement.nextElementSibling.classList.remove('opened');
            }
        });
    });

    Array.from(document.querySelectorAll('.faq__item')).forEach((item) => {
        let opened = false;


        item.querySelector('.btn--more').addEventListener('click', (e) => {
            e.preventDefault();

            opened = !opened;
            e.target.innerHTML = opened ? '-' : '+';
            if (opened) {
                item.querySelector('.faq__content').classList.add('opened');
            } else {
                item.querySelector('.faq__content').classList.remove('opened');
            }
        });
    });

    function doScrolling(elementY, duration) {
        const startingY = window.pageYOffset;

        const diff = elementY - startingY;
        let start;

        const animationStep = (timestamp) => {
            if (!start) start = timestamp;
            // Elapsed milliseconds since start of scrolling.
            const time = timestamp - start;
            // Get percent of completion in range [0, 1].
            const percent = Math.min(time / duration, 1);

            window.scrollTo(0, startingY + diff * percent);

            // Proceed with animation as long as we wanted it to.
            if (time < duration) {
                window.requestAnimationFrame(animationStep);
            }
        };

        // Bootstrap our animation - it will get called right before next frame shall be rendered.
        window.requestAnimationFrame(animationStep);
    }

    Array.from(document.querySelectorAll('a')).forEach((anchor) => {
        anchor.addEventListener('click', (e) => {
            const href = anchor.getAttribute('href');

            if (href.charAt(0) == '#') {
                e.preventDefault();
                e.stopPropagation();

                const element = document.querySelector(href);
                if (element) {
                    doScrolling(element.offsetTop, 1000);
                }
            }

        });
    });

    const homepageHeader = document.querySelector('.hp-header');

    const checkNavbarScoll = () => {
        if (window.scrollY > 50) {
            homepageHeader.querySelector('.header__nav').classList.add('nav--bg');
        } else {
            homepageHeader.querySelector('.header__nav').classList.remove('nav--bg');
        }
    }

    if (homepageHeader) {
        window.addEventListener('scroll', function (e) {
            checkNavbarScoll();
        });

        checkNavbarScoll();
    }

    const loginButton = document.querySelector('.btn--login');
    const quickLogin = document.querySelector('.quick-login-container');

    if (loginButton && quickLogin && window.innerWidth >= 768) {
        loginButton.addEventListener('click', (e) => {
            e.preventDefault();

            quickLogin.classList.toggle('active');
        })
    }

    const visibleButtons = document.querySelectorAll('.button-visible');
    Array.from(visibleButtons).forEach((button) => {
        button.addEventListener('click', () => {
            const passwordInput = button.parentElement.querySelector('input');

            passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password';
        })
    })
})();

